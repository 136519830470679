import { Button, FormControl } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

const RejectModal = (props: {
  show: boolean;
  title: React.ReactNode;
  inputValue: string;
  onInputChange: (value: string) => void;
  onConfirm: () => void;
  onCancel: () => void;
}) => {
  return (
    <Modal show={props.show} onHide={props.onCancel} centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormControl
          type="text"
          value={props.inputValue}
          onChange={(e) => props.onInputChange(e.target.value)}
          placeholder="Enter rejected reason"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={props.onCancel}>
          Cancel
        </Button>
        <Button onClick={props.onConfirm}>Confirm</Button>
      </Modal.Footer>
    </Modal>
  );
};
export { RejectModal };
