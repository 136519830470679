import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Form, Spinner, Stack, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { ScrollableContent } from 'src/components/ScrollableContent';
import { IActivity } from 'src/models/Activity';
import { toast } from 'react-toastify';
import {
  fetchActivities,
  getActivities,
} from 'src/redux/features/session/slice';
import { TableLoader } from 'src/views/TimeSheet/components/TableLoader';
import {
  assignActivityToProject,
  fetchProjectsById,
} from 'src/redux/features/projects/projectsSlice';
import { getUserRoles } from 'src/redux/features/users/usersSlice';
import { CanView } from 'src/components/CanView';
import { isAuthorised } from 'src/utils/helpers';

const GlobalActivities = ({ active }) => {
  const dispatch = useDispatch();

  const columns = ['Name', 'Capitalizable', 'Status', ''];
  const addActivityBtnRef = useRef(null);
  const { data: activities, loading: activitiesLoading } =
    useSelector(getActivities);

  const userRoles = useSelector(getUserRoles);

  const [data, setData] = useState<IActivity[]>(
    activities.filter((act) => act.isDefault),
  );
  const [isSaving, setIsSaving] = useState(false);

  const addRow = () => {
    // const hasAllActivities = activities.every((item) =>
    //   data.some((other) => other.id === item.id),
    // );

    // if (hasAllActivities) {
    //   toast.error('All the activites are added to this project.');

    //   return;
    // }

    const usedActivities = data.map((it) => it.name);
    const unUsedActivities = activities.filter((activity) => {
      return usedActivities.indexOf(activity.name) === -1;
    });

    const newRow: IActivity = {
      id: data.length + 1,
      name: unUsedActivities[0]?.name || '',
      isActive: true,
      isNew: true,
      isDefault: true,
      isCapitalizable: true,
    };
    const updatedDate = [...data];

    updatedDate.unshift(newRow);
    setData(updatedDate);
  };

  const removeRow = (index) => {
    const newData = [...data];

    newData.splice(index, 1);
    setData(newData);
  };

  const onAvtivityTextChange = (e, index) => {
    const activityName = e.target.value;
    const newData = [...data];

    newData[index] = {
      ...newData[index],
      name: activityName,
    };

    setData([...newData]);
  };

  const onStatusChange = (index) => {
    const clonedData = data.map((it) => {
      return { ...it };
    });

    clonedData[index].isActive = !clonedData[index].isActive;
    setData([...clonedData]);
  };

  const onCapitalizableChange = (index) => {
    const clonedData = data.map((it) => {
      return { ...it };
    });

    clonedData[index].isCapitalizable = !clonedData[index].isCapitalizable;
    setData([...clonedData]);
  };

  const getActivitiesByProjectId = async (id: number) => {
    await dispatch(fetchProjectsById({ id, skipError: true }));
  };

  function identifyDuplicateNames(data) {
    const nameSet = new Set();
    const duplicates = [];

    for (const item of data) {
      const name = item.name;

      if (nameSet.has(name)) {
        duplicates.push(name);
      } else {
        nameSet.add(name);
      }
    }

    return duplicates;
  }

  const onSave = async () => {
    const isActivitiesValid = data.every((item) => item.name !== '');

    if (!isActivitiesValid) {
      toast.error('Please select activity name.');

      return;
    }
    const duplicateActivities = identifyDuplicateNames(data);

    if (duplicateActivities && duplicateActivities.length > 0) {
      toast.error('Activity name should be unique.');

      return;
    }

    setIsSaving(true);

    const response = await dispatch(
      assignActivityToProject({
        id: 0,
        payload: {
          activities: data.map((it) => {
            return {
              ...it,
              isDefault: true,
              id: it.isNew ? 0 : it?.id,
            };
          }),
        },
      }),
    );

    setIsSaving(false);
    if (response.error) {
      return;
    }

    toast.success('Activity saved successfully.');
    getActivitiesByProjectId(0);
    await dispatch(fetchActivities());
  };

  useEffect(() => {
    if (active === 'global') dispatch(fetchActivities());
  }, [dispatch, active]);
  useEffect(() => {
    setData(activities.filter((act) => act.isDefault));
  }, [activities]);

  return (
    <Card bg="light" text="dark" className="h-100 bg-white border-0">
      <Card.Header className="bg-white border-0">
        <Stack
          direction="horizontal"
          gap={2}
          className="justify-content-end mb-2"
        >
          {!activitiesLoading && (
            <CanView userRole={userRoles.data} allowedRoles={['CapexAdmin']}>
              <Button
                ref={addActivityBtnRef}
                className="text-white"
                variant="primary"
                onClick={addRow}
              >
                Add Activity
              </Button>
            </CanView>
          )}
        </Stack>
      </Card.Header>

      <Card.Body className="h-full overflow-hidden pt-0">
        <ScrollableContent
          minHeight="calc(100vh - 320px)"
          maxHeight="calc(100vh - 320px)"
        >
          {!activitiesLoading ? (
            <Table>
              <thead
                className="position-sticky top-0 bg-white"
                style={{ zIndex: 100 }}
              >
                <tr className="border-1">
                  {columns.map((col, index) => (
                    <th
                      className="text-start fw-bolder p-3"
                      key={`${col}-${index}`}
                    >
                      {col}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data?.length ? (
                  data?.map((item, index) => (
                    <tr
                      key={`${item.id}-${index}`}
                      className="text-start border-1"
                    >
                      <td className="p-3">
                        <Form.Control
                          type="text"
                          id={`activity${index}`}
                          placeholder="Enter Activity Name"
                          value={item?.name}
                          disabled={
                            !isAuthorised(userRoles.data, ['CapexAdmin'])
                          }
                          onChange={(e) => onAvtivityTextChange(e, index)}
                        />
                      </td>

                      <td className="p-3">
                        <Form.Check
                          type="switch"
                          id="custom-switch2"
                          label=""
                          className="mt-2"
                          disabled={
                            !isAuthorised(userRoles.data, ['CapexAdmin'])
                          }
                          defaultChecked={item?.isCapitalizable}
                          onChange={() => onCapitalizableChange(index)}
                        />
                      </td>

                      <td className="p-3">
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label=""
                          className="mt-2"
                          defaultChecked={item?.isActive}
                          disabled={
                            !isAuthorised(userRoles.data, ['CapexAdmin'])
                          }
                          onChange={() => onStatusChange(index)}
                        />
                      </td>
                      <td className="text-end">
                        {item?.isNew ? (
                          <Button
                            variant="link"
                            onClick={() => removeRow(index)}
                          >
                            <i className="bi bi-trash3 fs-5"></i>
                          </Button>
                        ) : (
                          <Button
                            variant="link"
                            style={{ visibility: 'hidden' }}
                          >
                            <i className="bi bi-trash3 fs-5"></i>
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="p-5 border-1">
                    <td colSpan={4} className="p-5">
                      No Data available
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          ) : (
            <TableLoader />
          )}
        </ScrollableContent>
      </Card.Body>
      <Card.Footer className="bg-white">
        <Stack
          direction="horizontal"
          gap={2}
          className="justify-content-end mb-2"
        >
          {/* <Button variant="outline-primary" className="">
            Reset
          </Button> */}
          <CanView userRole={userRoles.data} allowedRoles={['CapexAdmin']}>
            <Button className="text-white" onClick={onSave}>
              {isSaving ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-2 border-1 text-dark"
                  />
                  Saving...
                </>
              ) : (
                'Save'
              )}
            </Button>
          </CanView>
        </Stack>
      </Card.Footer>
    </Card>
  );
};

export default GlobalActivities;
