import React from 'react';
import { CanView } from '../CanView';
import { useAuth } from 'react-oidc-context';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUserRoles } from 'src/redux/features/users/usersSlice';

export const SideNavItems = () => {
  const auth = useAuth();
  const location = useLocation();

  const userRoles = useSelector(getUserRoles);

  const selectedSidebarStyle: React.CSSProperties = {
    width: '3%',
    height: '3vh',
    backgroundColor: 'dodgerblue',
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px',
    marginLeft: '-23px',
    position: 'absolute',
  };

  const navItems = [
    { to: '/dashboard', icon: 'bi bi-clipboard-data', label: 'Dashboard' },
    { to: '/timesheet', icon: 'bi bi-calendar2-minus', label: 'Time Sheet' },
    {
      to: '/team-timesheet',
      icon: 'bi bi-people',
      label: 'Team Time Sheet',
      allowedRoles: ['CapexAdmin', 'CapexAdManager'],
    },
    {
      to: '/monthlysheet',
      icon: 'bi bi-calendar-range',
      label: 'Monthly Sheet',
      allowedRoles: ['CapexAdmin'],
    },
    {
      to: '/manage-activities',
      icon: 'bi bi-border-style',
      label: 'Manage Activities',
      allowedRoles: ['CapexAdmin', 'CapexProjectLeader'],
    },
    {
      to: '/manage-projects',
      icon: 'bi bi-gear',
      label: 'Manage Projects',
    },
  ];

  const isActiveNavLink = navItems.some((item) =>
    location.pathname.startsWith(item.to),
  );

  const renderNavItem = (
    to: string,
    icon: string,
    label: string,
    allowedRoles?: string[],
  ) => {
    const isActive = location.pathname.startsWith(to);

    const navItem = (
      <li
        className="d-flex align-items-center justify-content-start text-primary py-2"
        style={{ position: 'relative' }}
        key={to}
      >
        {(isActive || (!isActiveNavLink && to === '/timesheet')) && (
          <div style={selectedSidebarStyle}></div>
        )}
        <i className={`${icon} fs-5`}></i>
        <NavLink to={to} className="text-decoration-none ms-2">
          {label}
        </NavLink>
      </li>
    );

    return allowedRoles ? (
      <>
        <CanView key={to} userRole={userRoles.data} allowedRoles={allowedRoles}>
          {navItem}
        </CanView>
      </>
    ) : (
      navItem
    );
  };

  return (
    <nav style={{ position: 'sticky', top: '0', zIndex: '1000' }}>
      <ul className="px-3" style={{ listStyleType: 'none' }}>
        {navItems.map((item) =>
          renderNavItem(item.to, item.icon, item.label, item.allowedRoles),
        )}
      </ul>
    </nav>
  );
};
