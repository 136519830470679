// React imports
import React, { useState } from 'react';

// Third-party imports
import { Typeahead } from 'react-bootstrap-typeahead';
import { useSelector } from 'react-redux';

import { getProjects } from 'src/redux/features/session/slice';

export const ProjectsAutoComplete = ({
  onSelectedProjectChange,
  style,
  selectedProject,
}: {
  onSelectedProjectChange: any;
  style: any;
  selectedProject?: any;
}) => {
  const [singleSelections, setSingleSelections] = useState([]);
  const { data: projects } = useSelector(getProjects);

  const customFilterBy = (
    option: { name: string },
    props: { text: string },
  ) => {
    return option.name.toLowerCase().includes(props.text.toLowerCase());
  };

  return (
    <Typeahead
      filterBy={customFilterBy}
      id="basic-typeahead-single"
      labelKey="name"
      options={
        selectedProject
          ? projects.filter((val) => val.name != selectedProject)
          : projects
      }
      onChange={(selected) => {
        setSingleSelections(selected);
        onSelectedProjectChange(selected);
      }}
      placeholder="Select Project"
      selected={singleSelections}
      style={style}
    />
  );
};

export default ProjectsAutoComplete;
