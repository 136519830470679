import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  Spinner,
  Form,
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import ProjectsAutoComplete from 'src/components/ProjectsAutoComplete';
import { AssignedProjectsAutoComplete } from 'src/components/AssignedProjectsAutoComplete';
import { ScrollableContent } from 'src/components/ScrollableContent';
import { IActivity } from 'src/models/Activity';
import { IProjectDetails, IProjectResponse } from 'src/models/Project';

import {
  assignActivityToProject,
  fetchProjectsById,
  getProjectById,
} from 'src/redux/features/projects/projectsSlice';
import { getUserRoles } from 'src/redux/features/users/usersSlice';
import { isAuthorised } from 'src/utils/helpers';
import { TableLoader } from 'src/views/TimeSheet/components/TableLoader';

interface CopyModalProps {
  showCopyModal: boolean;
  handleClose: () => void;
  isModalOpen: boolean;
  selectedProject: string;
  selectedActivity: IActivity[];
}

export const CopyActivityModal: React.FC<CopyModalProps> = ({
  showCopyModal,
  handleClose,
  isModalOpen,
  selectedProject,
  selectedActivity,
}) => {
  const [selectedProjectData, setSelectedProjectData] =
    useState<IProjectResponse | null>(null);
  const [activityData, setActivityData] = useState<IActivity[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [selectedActivities, setSelectedActivities] = useState<IActivity[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const dispatch = useDispatch();
  const {
    data: activityDetails,
    loading: activitiesLoading,
  }: { data: IProjectDetails; loading: boolean } = useSelector(getProjectById);
  const userRoles = useSelector(getUserRoles);
  const onSelectedProjectChange = (selectedItem: IProjectResponse) => {
    if (selectedItem && selectedItem[0]) {
      setSelectedProjectData(selectedItem[0]);
      getActivitiesByProjectId(selectedItem[0].id);
    } else {
      setSelectedProjectData(null);
    }
  };

  const getActivitiesByProjectId = async (id: number) => {
    await dispatch(fetchProjectsById({ id }));
  };

  const handleCheckboxChange = (activity: IActivity) => {
    setSelectedActivities((prevState) =>
      prevState.some((a) => a.id === activity.id)
        ? prevState.filter((a) => a.id !== activity.id)
        : [...prevState, activity],
    );
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedActivities([]);
    } else {
      setSelectedActivities(selectedActivity.filter((item) => !item.isDefault));
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    const filteredData = selectedActivity.filter((item) => !item.isDefault);

    if (selectedActivities.length === filteredData.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedActivities, selectedActivity]);

  const handleCopySave = async () => {
    if (!selectedProjectData) {
      toast.error('Please select a project.');

      return;
    }

    const targetActivityNames = activityData.map((activity) => activity.name);
    const hasDuplicates = selectedActivities.some((activity) =>
      targetActivityNames.includes(activity.name),
    );

    if (hasDuplicates) {
      toast.error(`Selected activities are already in the target project.`);

      return;
    }

    setIsSaving(true);

    const response = await dispatch(
      assignActivityToProject({
        id: selectedProjectData?.id,
        payload: {
          activities: selectedActivities.map((activity) => ({
            ...activity,
            id: 0,
          })),
        },
      }),
    );

    setIsSaving(false);
    if (response.error) {
      return;
    }
    toast.success('Activities copied successfully.');
    setTimeout(() => {
      handleClose();
    }, 2000);
  };

  useEffect(() => {
    if (selectedProjectData?.id) {
      setActivityData(activityDetails.activityTypes as IActivity[]);
    }
  }, [selectedProjectData?.id, activityDetails]);

  useEffect(() => {
    if (!isModalOpen) {
      setSelectedProjectData(null);
      setActivityData([]);
      setSelectedActivities([]);
      setSelectAll(false);
    }
  }, [isModalOpen]);

  // Inside the CopyActivityModal component
  return (
    <Modal show={showCopyModal} onHide={handleClose} scrollable size="xl">
      <Modal.Header closeButton>
        <Container>
          <Row>
            <Col>Selected Source Project</Col>
            <Col>Select Target Project</Col>
          </Row>
        </Container>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col>
              <Form.Control
                className="bg-white"
                type="text"
                disabled
                value={selectedProject}
              />
            </Col>
            <Col>
              {isAuthorised(userRoles.data, ['CapexAdmin']) ? (
                <ProjectsAutoComplete
                  onSelectedProjectChange={onSelectedProjectChange}
                  style={{ width: '100%' }}
                  selectedProject={selectedProject}
                />
              ) : (
                <AssignedProjectsAutoComplete
                  style={{ width: '100%' }}
                  onSelectedProjectChange={onSelectedProjectChange}
                  selectedProject={selectedProject}
                />
              )}
            </Col>
          </Row>
        </Container>
        <ScrollableContent minHeight="100%" maxHeight="100%">
          <Container>
            <Row>
              <Col>
                <ul className="bg-lightgray border-light-gray px-2 py-2 list-unstyled border border-light-subtle">
                  <li className="d-flex align-items-center mb-3 ps-2">
                    <Form.Check
                      type="checkbox"
                      className="me-2"
                      checked={selectAll}
                      style={{
                        transform: 'scale(1.3)',
                      }}
                      onChange={handleSelectAllChange}
                    />
                    <span className="fw-bold ms-4">Select All Activities</span>
                  </li>
                  {selectedProject && selectedActivity.length ? (
                    selectedActivity
                      .filter((val) => !val.isNew)
                      .map((item) => (
                        <li
                          className="d-flex align-items-center mb-3 ps-2"
                          key={item.id}
                        >
                          <div className="d-flex align-items-center w-100">
                            <div style={{ flexShrink: 0, marginRight: '12px' }}>
                              {!item.isDefault ? (
                                <Form.Check
                                  type="checkbox"
                                  checked={selectedActivities.some(
                                    (a) => a.id === item.id,
                                  )}
                                  onChange={() => handleCheckboxChange(item)}
                                  style={{
                                    transform: 'scale(1.3)',
                                  }}
                                />
                              ) : (
                                <div style={{ width: '20px' }}></div>
                              )}
                            </div>

                            <div style={{ flexGrow: 1 }}>
                              <span className="fs-5 d-block ms-4">
                                {item.name}
                              </span>
                            </div>
                          </div>
                        </li>
                      ))
                  ) : (
                    <li className="align-items-center p-3">
                      No Data available
                    </li>
                  )}
                </ul>
              </Col>
              <Col>
                <ul className="bg-lightgray border-light-gray px-2 py-2 list-unstyled border border-light-subtle">
                  {selectedProjectData?.id && activityData.length ? (
                    activityData.map((item) => (
                      <li
                        className="d-flex align-items-center mb-3 ps-2"
                        key={item.id}
                      >
                        <span className="fs-5 d-block ms-4">{item.name}</span>
                      </li>
                    ))
                  ) : (
                    <li style={{ textAlign: 'center', padding: '20px' }}>
                      No Data available
                    </li>
                  )}
                </ul>
              </Col>
            </Row>
          </Container>
        </ScrollableContent>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleCopySave}
          disabled={!selectedActivities.length || isSaving}
        >
          {isSaving ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-2"
              />
              Saving...
            </>
          ) : (
            'Save'
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
