// React imports
import React, { useEffect, useState } from 'react';

// Third-party imports
import {
  Button,
  Card,
  Form,
  InputGroup,
  Spinner,
  Stack,
  Table,
} from 'react-bootstrap';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// App imports
import { ProjectsAutoComplete } from 'src/components/ProjectsAutoComplete';
import { ScrollableContent } from 'src/components/ScrollableContent';
import { IProjectDetails, IProjectResponse } from 'src/models/Project';
import {
  assignProject,
  fetchProjectsById,
  getProjectById,
} from 'src/redux/features/projects/projectsSlice';
import { ResourceAutoComplete } from 'src/components/ResourceAutoComplete';
import { IResource } from 'src/models/Resource';
import { toast } from 'react-toastify';
import { fetchAssignedProjects } from 'src/redux/features/session/slice';
import { TableLoader } from 'src/views/TimeSheet/components/TableLoader';
import { formatDate } from 'src/utils/helpers';

const AddProject = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const columns = ['Resource', 'Start Date', 'End Date', 'Status', ''];

  const {
    data: projectDetails,
    loading: projectLoading,
  }: {
    data: IProjectDetails;
    loading: boolean;
  } = useSelector(getProjectById);

  const [selectedProject, setSelectedProject] = useState<IProjectResponse>();
  const [isSaving, setIsSaving] = useState(false);
  const [data, setData] = useState<IResource[]>([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const addRow = () => {
    const newRow: IResource = {
      id: data.length + 1,
      resource: '',
      startDate: formatDate(projectDetails.startDate),
      endDate: formatDate(projectDetails.endDate),
      status: true,
      isActive: true,
      isNewResource: true,
    };
    const updatedDate = [...data];

    updatedDate.unshift(newRow);
    setData(updatedDate);
  };

  const removeRow = (index) => {
    const newData = [...data];

    newData.splice(index, 1);
    setData(newData);
  };

  const onSelectedProjectChange = (selectedItem: IProjectResponse) => {
    if (selectedItem && selectedItem[0]) {
      setSelectedProject(selectedItem[0]);
      getResourcesByProject(selectedItem[0].id);
    } else {
      setSelectedProject(null);
    }
  };

  const onSelectedResourceChange = (selected, resource, index) => {
    if (selected && selected[0]) {
      if (selected[0].customOption) {
        data[index].resource = selected[0].email;
      } else {
        data[index].resource = selected[0].email;
      }
      setData([...data]);
    }
    if (selected.length === 0) {
      data[index].resource = '';

      setData([...data]);
    }
  };

  const getResourcesByProject = (id: number) => {
    dispatch(fetchProjectsById({ id }));
  };

  const onSave = async () => {
    if (projectDetails.resources.length === data.length) {
      return;
    }

    const newResources = data.filter((item) => item.isNewResource);

    const isResourcesValid = newResources.every((item) => item.resource !== '');
    const isStartDateEntered = newResources.every(
      (item) => item.startDate !== 'Invalid Date',
    );
    const isEndDateEntered = newResources.every(
      (item) => item.endDate !== 'Invalid Date',
    );

    if (!isResourcesValid) {
      toast.error('Please select resource name.');

      return;
    }
    if (!isStartDateEntered) {
      toast.error('Please select Start Date for the new Resource');

      return;
    }
    if (!isEndDateEntered) {
      toast.error('Please select End Date for the new Resource');

      return;
    }
    const resourceName = data.map((x) => x.resource);
    const counts = {};
    const duplicates = [];

    resourceName.forEach((value) => {
      if (counts[value]) {
        counts[value]++;
      } else {
        counts[value] = 1;
      }
    });

    for (const value in counts) {
      if (counts[value] > 1) {
        duplicates.push(value);
      }
    }

    if (duplicates.length > 0) {
      toast.error(
        `${duplicates.join(', ')} is already added in ${selectedProject.name} project.`,
      );

      return;
    }

    setIsSaving(true);

    await dispatch(
      assignProject({
        id: projectDetails.id,
        payload: newResources,
      }),
    );

    setIsSaving(false);
    toast.success('Resource assigned successfully.');
    // dispatch(actions.resetProjects());
    await dispatch(fetchAssignedProjects());

    const updatedDetails = [...data].map((item) => {
      return { ...item, isNewResource: false };
    });

    setData([...updatedDetails]);
  };

  const onStartDateChange = (selectedDate, index) => {
    const updatedDate = { ...data[index] };

    const formattedDate = formatDate(selectedDate);

    updatedDate.startDate = formattedDate;
    setStartDate(formattedDate);
    data[index] = { ...updatedDate };

    setData([...data]);
  };

  const onEndDateChange = (date, index) => {
    const formattedDate = formatDate(date);

    data[index].endDate = formattedDate;
    setEndDate(formattedDate);
    setData([...data]);
  };

  const onStatusChange = (index) => {
    data[index].status = !data[index].status;
    setData([...data]);
  };

  useEffect(() => {
    const formattedResources = projectDetails.resources.map((resource) => ({
      ...resource,
      startDate: formatDate(resource.startDate),
      endDate: formatDate(resource.endDate),
    }));

    setData(formattedResources);
    if (formattedResources.length > 0) {
      setStartDate(formattedResources[0].startDate);
      setEndDate(formattedResources[0].endDate);
    }
  }, [projectLoading]);
  const minStartDate = formatDate(projectDetails.startDate);
  const maxEndDate = formatDate(projectDetails.endDate);

  const previousScreen = location?.state?.from;
  const handleBackClick = () => {
    if (previousScreen === 'timesheet') {
      navigate('/timesheet');
    } else if (previousScreen === 'manage-resource') {
      navigate('/manage-resource');
    } else if (previousScreen === 'team-timesheet') {
      navigate('/team-timesheet');
    } else {
      navigate(-1);
    }
  };

  return (
    <Card bg="light" text="dark" className="h-100 bg-white border-0 shadow">
      <Card.Header className="bg-white my-2 border-0">
        <Stack
          direction="horizontal"
          gap={3}
          className="justify-content-between"
        >
          <Stack direction="horizontal" gap={3}>
            <Link to={''} onClick={handleBackClick}>
              <i className="bi bi-arrow-left-short fs-4"></i>
            </Link>
            <ProjectsAutoComplete
              onSelectedProjectChange={onSelectedProjectChange}
              style={{ width: '400px' }}
            />
          </Stack>

          {!projectLoading && selectedProject?.id && (
            <Button className="text-white" onClick={addRow}>
              Add Resource
            </Button>
          )}
        </Stack>
      </Card.Header>
      <Card.Body className="h-full pt-0 overflow-hidden">
        <ScrollableContent minHeight="100%" maxHeight="100%">
          {!projectLoading ? (
            <Table>
              <thead
                className="position-sticky top-0 bg-white"
                style={{ zIndex: 100 }}
              >
                <tr className="border-1">
                  {columns.map((col, index) => (
                    <th
                      className="text-start fw-bolder p-3"
                      key={`${col}-${index}`}
                    >
                      {col}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {selectedProject && data?.length ? (
                  data?.map((resource, index) => (
                    <tr
                      key={`${resource.id}-${index}`}
                      className="text-start border-1"
                    >
                      <td className="p-3">
                        <ResourceAutoComplete
                          selectedItems={
                            resource?.resource ? [resource?.resource] : []
                          }
                          disabled={!resource.isNewResource}
                          onSelectedResourceChange={(selected) => {
                            onSelectedResourceChange(selected, resource, index);
                          }}
                          style={{ minWidth: '300px' }}
                        />
                      </td>
                      <td className="p-3">
                        <InputGroup>
                          <InputGroup.Text id="basic-addon1">
                            <i className="bi bi-calendar-date"></i>
                          </InputGroup.Text>
                          <Form.Control
                            type="date"
                            value={resource?.startDate}
                            onChange={(e) =>
                              onStartDateChange(e.target.value, index)
                            }
                            placeholder="Select Start Date"
                            aria-label="Select Start Date"
                            aria-describedby="Start Date"
                            disabled={!resource.isNewResource}
                            min={minStartDate}
                            max={resource.endDate || maxEndDate}
                          />
                        </InputGroup>
                      </td>
                      <td className="p-3">
                        <InputGroup>
                          <InputGroup.Text id="basic-addon1">
                            <i className="bi bi-calendar-date"></i>
                          </InputGroup.Text>
                          <Form.Control
                            type="date"
                            value={resource?.endDate}
                            onChange={(e) =>
                              onEndDateChange(e.target.value, index)
                            }
                            placeholder="Select End Date"
                            aria-label="Select End Date"
                            aria-describedby="End Date"
                            disabled={!resource.isNewResource}
                            min={resource.startDate || minStartDate}
                            max={maxEndDate}
                          />
                        </InputGroup>
                      </td>
                      <td className="p-3">
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label=""
                          className="mt-2"
                          defaultChecked={resource?.status}
                          onChange={() => onStatusChange(index)}
                          disabled={!resource.isNewResource}
                        />
                      </td>
                      <td>
                        <Button
                          variant="link"
                          onClick={() => removeRow(index)}
                          disabled={!resource?.isNewResource}
                        >
                          <i className="bi bi-trash3 fs-5"></i>
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="p-5 border-1">
                    <td colSpan={4} className="p-5">
                      No Data available
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          ) : (
            <TableLoader />
          )}
        </ScrollableContent>
      </Card.Body>
      {data?.length ? (
        <Card.Footer className="d-flex bg-white">
          {!projectLoading && selectedProject?.id && (
            <Button
              className="text-white ms-auto"
              disabled={
                isSaving || data?.length <= projectDetails?.resources?.length
              }
              onClick={onSave}
            >
              {isSaving ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-2 border-1 text-dark"
                  />
                  Saving...
                </>
              ) : (
                'Save'
              )}
            </Button>
          )}
        </Card.Footer>
      ) : (
        ''
      )}
    </Card>
  );
};

export default AddProject;
