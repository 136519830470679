// React imports
import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Form, Spinner, Stack, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

// App imports
import { ScrollableContent } from 'src/components/ScrollableContent';
import { IProjectDetails, IProjectResponse } from 'src/models/Project';
import { TableLoader } from 'src/views/TimeSheet/components/TableLoader';
import { IActivity } from 'src/models/Activity';

import {
  assignActivityToProject,
  fetchProjectsById,
  getProjectById,
} from 'src/redux/features/projects/projectsSlice';
import { CopyActivityModal } from '../CopyActivityModal';
import ProjectsAutoComplete from 'src/components/ProjectsAutoComplete';
import {
  getActivities,
  getAssignedProjects,
} from 'src/redux/features/session/slice';
import { toast } from 'react-toastify';
import { AssignedProjectsAutoComplete } from 'src/components/AssignedProjectsAutoComplete';
import { CanView } from 'src/components/CanView';
import { getUserRoles } from 'src/redux/features/users/usersSlice';
import { isAuthorised } from 'src/utils/helpers';

const ProjectActivities = ({ isModalOpen }) => {
  const dispatch = useDispatch();
  const addActivityBtnRef = useRef(null);

  const columns = ['Name', 'Activity Type', 'Capitalizable', 'Status', ''];
  const { data: activities, loading: activitiesLoading } =
    useSelector(getActivities);

  const userRoles = useSelector(getUserRoles);

  const { data: projects, loading: projectsLoading } =
    useSelector(getAssignedProjects);

  const {
    data: activityDetails,
    loading: activitiesDetailsLoading,
  }: {
    data: IProjectDetails;
    loading: boolean;
  } = useSelector(getProjectById);

  const [selectedProject, setSelectedProject] = useState<IProjectResponse>();
  const [isSaving, setIsSaving] = useState(false);
  const [data, setData] = useState<IActivity[]>(activities);
  const [copyActivity, setCopyActivity] = useState<IActivity[]>(activities);
  const [showCopyModal, setShowCopyModal] = useState(false);

  const addRow = () => {
    const newRow: IActivity = {
      id: data.length + 1,
      name: '',
      isNew: true,
      isDefault: false,
      isCapitalizable: true,
      isActive: true,
    };
    const updatedDate = [...data];

    updatedDate.unshift(newRow);
    setData(updatedDate);
  };

  const onAvtivityTextChange = (e, index) => {
    const activityName = e.target.value;
    const newData = [...data];

    newData[index].name = activityName;

    setData([...newData]);
  };

  const removeRow = (index) => {
    const newData = [...data];

    newData.splice(index, 1);
    setData(newData);
  };

  const handleClose = () => {
    setShowCopyModal(false);
  };

  const getActivitiesByProjectId = async (id: number) => {
    const result = await dispatch(fetchProjectsById({ id }));

    setCopyActivity(result.payload.activityTypes);
  };

  const onStatusChange = (index) => {
    data[index].isActive = !data[index].isActive;
    setData([...data]);
  };

  const onCapitalizableChange = (index) => {
    data[index].isCapitalizable = !data[index].isCapitalizable;
    setData([...data]);
  };

  const onCopy = () => {
    setShowCopyModal(true);
  };

  const onSelectedProjectChange = (selectedItem: IProjectResponse) => {
    if (selectedItem && selectedItem[0]) {
      setSelectedProject(selectedItem[0]);
      getActivitiesByProjectId(selectedItem[0].id);
    } else {
      setSelectedProject(null);
    }
  };

  function identifyDuplicateNames(data) {
    const nameSet = new Set();
    const duplicates = [];

    for (const item of data) {
      const name = item.name;

      if (nameSet.has(name)) {
        duplicates.push(name);
      } else {
        nameSet.add(name);
      }
    }

    return duplicates;
  }

  const onSave = async () => {
    const isActivitiesValid = data.every((item) => item.name !== '');

    if (!isActivitiesValid) {
      toast.error('Please enter activity name.');

      return;
    }
    const duplicateActivities = identifyDuplicateNames(data);

    if (duplicateActivities && duplicateActivities.length > 0) {
      toast.error('Activity name should be unique.');

      return;
    }

    setIsSaving(true);

    const response = await dispatch(
      assignActivityToProject({
        id: selectedProject?.id,
        payload: {
          activities: data
            .filter((it) => !it.isDefault)
            .map((it) => {
              return {
                ...it,
                isDefault: false,
                id: it.isNew ? 0 : it.id,
              };
            }),
        },
      }),
    );

    setIsSaving(false);
    if (response.error) {
      return;
    }

    toast.success('Activity saved successfully.');
    getActivitiesByProjectId(selectedProject.id);
  };

  useEffect(() => {
    if (selectedProject?.id && !showCopyModal) {
      setData(activityDetails.activityTypes as IActivity[]);
    }
  }, [selectedProject?.id, activityDetails]);

  useEffect(() => {
    setData(activities.filter((act) => !act.isDefault));
  }, [activities]);

  return (
    <Card bg="light" text="dark" className="h-100 bg-white border-0">
      <Card.Header className="bg-white border-0">
        <Stack
          direction="horizontal"
          gap={3}
          className="justify-content-between mb-2"
        >
          <Stack direction="horizontal" gap={3}>
            {isAuthorised(userRoles.data, ['CapexAdmin']) ? (
              <ProjectsAutoComplete
                onSelectedProjectChange={onSelectedProjectChange}
                style={{ width: '400px' }}
              />
            ) : (
              <AssignedProjectsAutoComplete
                style={{ width: '400px' }}
                onSelectedProjectChange={onSelectedProjectChange}
              />
            )}
          </Stack>
          {!activitiesLoading && selectedProject?.id && (
            <Stack direction="horizontal" gap={2}>
              <Button
                ref={addActivityBtnRef}
                className="text-white"
                variant="primary"
                onClick={addRow}
              >
                Add Activity
              </Button>
              <Button
                ref={addActivityBtnRef}
                className="text-black"
                variant="outline-primary"
                onClick={onCopy}
                disabled={!copyActivity.find((status) => !status.isDefault)}
              >
                Copy
              </Button>
            </Stack>
          )}
        </Stack>
      </Card.Header>

      <Card.Body className="h-full overflow-hidden pt-0">
        <ScrollableContent
          minHeight="calc(100vh - 320px)"
          maxHeight="calc(100vh - 320px)"
        >
          {!activitiesLoading ? (
            <Table>
              <thead
                className="position-sticky top-0 bg-white"
                style={{ zIndex: 100 }}
              >
                <tr className="border-1">
                  {columns.map((col, index) => (
                    <th
                      className="text-start fw-bolder p-3"
                      key={`${col}-${index}`}
                    >
                      {col}
                    </th>
                  ))}
                </tr>
              </thead>
              {selectedProject?.id ? (
                <tbody>
                  {data && data?.length
                    ? data?.map((item, index) => (
                        <tr
                          key={`${item.id}-${index}`}
                          className="text-start border-1"
                        >
                          <td className="p-3">
                            <Form.Control
                              type="text"
                              id={`activity${index}`}
                              placeholder="Enter Activity Name"
                              value={item?.name}
                              disabled={item?.isDefault}
                              onChange={(e) => onAvtivityTextChange(e, index)}
                            />
                          </td>

                          <td className="p-3">
                            {item?.isDefault
                              ? 'Global Activity'
                              : 'Project Activity'}
                          </td>

                          <td className="p-3">
                            <Form.Check
                              type="switch"
                              id="custom-switch2"
                              label=""
                              className="mt-2"
                              checked={item?.isCapitalizable}
                              disabled={item?.isDefault}
                              onChange={() => onCapitalizableChange(index)}
                            />
                          </td>

                          <td className="p-3">
                            <Form.Check
                              type="switch"
                              id="custom-switch"
                              label=""
                              className="mt-2"
                              checked={item?.isActive}
                              disabled={item?.isDefault}
                              onChange={() => onStatusChange(index)}
                            />
                          </td>
                          <td className="text-end">
                            {item?.isNew ? (
                              <Button
                                variant="link"
                                onClick={() => removeRow(index)}
                              >
                                <i className="bi bi-trash3 fs-5"></i>
                              </Button>
                            ) : (
                              ''
                            )}
                          </td>
                        </tr>
                      ))
                    : ''}
                  {!data?.length ? (
                    <tr className="p-5 border-1">
                      <td colSpan={4} className="p-5">
                        No Data available
                      </td>
                    </tr>
                  ) : (
                    ''
                  )}
                </tbody>
              ) : (
                <tr className="p-5 border-1">
                  <td colSpan={4} className="p-5">
                    No Data available
                  </td>
                </tr>
              )}
            </Table>
          ) : (
            <TableLoader />
          )}
        </ScrollableContent>
        <CopyActivityModal
          showCopyModal={showCopyModal}
          handleClose={handleClose}
          isModalOpen={showCopyModal}
          selectedProject={selectedProject?.name}
          selectedActivity={copyActivity}
        />
      </Card.Body>

      <Card.Footer className="bg-white">
        {selectedProject && selectedProject.id ? (
          <Stack
            direction="horizontal"
            gap={2}
            className="justify-content-end mb-2"
          >
            {/* <Button variant="outline-primary" className="">
              Reset
            </Button> */}
            <Button className="text-white" onClick={onSave}>
              {isSaving ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-2 border-1 text-dark"
                  />
                  Saving...
                </>
              ) : (
                'Save'
              )}
            </Button>
          </Stack>
        ) : (
          ''
        )}
      </Card.Footer>
    </Card>
  );
};

export default ProjectActivities;
