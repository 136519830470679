import { useEffect, useRef, useState } from 'react';
import * as pbi from 'powerbi-client';
import { models, IEmbedConfiguration } from 'powerbi-client';
import { useDispatch, useSelector } from 'react-redux';
import {
  actions,
  fetchToken,
  getToken,
} from 'src/redux/features/users/usersSlice';
import React from 'react';
import { IPowerBIConfig } from 'src/models/PowerBIConfig';
import { useAuth } from 'react-oidc-context';

const Dashboard = () => {
  const dispatch = useDispatch();
  const auth = useAuth();
  const reportContainerRef = useRef<HTMLDivElement | null>(null);
  const [token, setToken] = useState<IPowerBIConfig>();

  const { data: response } = useSelector(getToken);
  const [hasTriedFetchingReport, setHasTriedFetchingReport] =
    React.useState(false);

  useEffect(() => {
    return () => {
      dispatch(actions.resetToken());
    };
  }, []);

  useEffect(() => {
    if (auth?.user?.profile?.email) {
      dispatch(fetchToken({ email: auth?.user?.profile?.email }));
    }
  }, [auth?.user?.profile?.email]);

  useEffect(() => {
    if (response) {
      setToken(response);
    }
  }, [response]);

  useEffect(() => {
    if (reportContainerRef.current && token && !hasTriedFetchingReport) {
      setHasTriedFetchingReport(true);
      const config: IEmbedConfiguration = {
        type: 'report',
        embedUrl: token.embededReportURL,
        id: token.reportId,
        accessToken: token.token.toString(),
        tokenType: models.TokenType.Embed,
        settings: {
          panes: {
            filters: { visible: true },
            pageNavigation: { visible: true },
          },
        },
      };

      const powerbiService = new pbi.service.Service(
        pbi.factories.hpmFactory,
        pbi.factories.wpmpFactory,
        pbi.factories.routerFactory,
      );

      powerbiService.reset(reportContainerRef.current);

      try {
        powerbiService.embed(reportContainerRef.current, config);
      } catch (err) {
        console.error('Error embedding the report:', err);
      }
    }
  }, [token]);

  return (
    <div
      ref={reportContainerRef}
      style={{ height: '100%', width: '100%' }}
    ></div>
  );
};

export default Dashboard;
