// React imports
import React, { useEffect, useState } from 'react';

// Third-party imports
import { Typeahead } from 'react-bootstrap-typeahead';
import { useAuth } from 'react-oidc-context';
import { useDispatch, useSelector } from 'react-redux';

// App imports
import { IReportee } from 'src/models/Reportee';
import { getResourceByManager } from 'src/redux/features/users/usersSlice';
import { fetchAllUserbyManager } from 'src/redux/features/users/usersSlice';

export const SelectReportee = ({ data = [], onItemChange }) => {
  const [options, setOptions] = useState<IReportee[]>(data);
  const filterByFields = ['name'];
  const auth = useAuth();
  const dispatch = useDispatch();

  const { data: managerReource, status: resourceLoading } =
    useSelector(getResourceByManager);

  useEffect(() => {
    dispatch(
      fetchAllUserbyManager({
        email: auth.user.profile.email,
      }),
    );
  }, [dispatch, auth.user.profile.email]);
  useEffect(() => {
    setOptions(managerReource);
  }, [managerReource]);

  return (
    <Typeahead
      className="w-25"
      filterBy={filterByFields}
      labelKey="name"
      id="name"
      options={options}
      placeholder="Select Reportee"
      onChange={onItemChange}
      allowNew={false}
      clearButton
      renderMenuItemChildren={(option: IReportee) => (
        <div key={option.email}>
          <div>{option?.name}</div>
        </div>
      )}
    />
  );
};
