import projectsReducer from './features/projects/projectsSlice';
import yearlyTimesheetReducer from './features/monthlysheet/monthlysheetSlice';
import sessionReducer from './features/session/slice';
import timesheetReducer from './features/timesheet/slice';
import userReducer from './features/users/usersSlice';
const rootReducer = {
  timesheet: timesheetReducer,
  yearlyTimesheet: yearlyTimesheetReducer,
  projects: projectsReducer,
  session: sessionReducer,
  users: userReducer,
};

export default rootReducer;
