// React imports
import React, { useEffect, useState } from 'react';

// Third party imports
import { Alert, Button, Card, Spinner, Stack } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from 'react-oidc-context';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

// App imports
import {
  ITimesheet,
  ITimesheetHistory,
  TimesheetModel,
  TimesheetStatusMap,
} from 'src/models/Timesheet';
import { GridTable } from './components/GridTable';
import { TableLoader } from './components/TableLoader';
import { Legends } from './components/Legends';
import { TimesheetFilters } from './components/Filters/TimesheetFilters';
import {
  getActivities,
  getAssignedProjects,
} from 'src/redux/features/session/slice';
import {
  actions,
  getTimesheet,
  getTimesheetByUser,
  getTimesheetHistory,
  getTimesheetHistoryData,
  saveTimesheet,
  submitTimesheet,
} from 'src/redux/features/timesheet/slice';
import { ConfirmModal } from 'src/components/ConfirmModal';
import { getDateandTime } from 'src/utils/helpers';
import { RejectModal } from 'src/components/RejectModal/Index';
import { CanView } from 'src/components/CanView';
import {
  getUserRoles,
  submitAutoFillInTimesheet,
} from 'src/redux/features/users/usersSlice';
import { HistoryModal } from 'src/components/HistoryModal';

const TimeSheet = ({ teamTime }: { teamTime?: boolean }) => {
  const dispatch = useDispatch();

  const timesheetModelObj = new TimesheetModel();
  const auth = useAuth();
  const manager = auth?.user?.profile?.peoplemanager === 'True';

  // Function to get the start of the week (Sunday)
  const getStartOfWeek = (date) => {
    const currentDay = dayjs(date).day();

    return dayjs(date).subtract(currentDay, 'day').toDate();
  };
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [weekType, setWeekType] = useState<'week' | 'work_week'>('work_week');
  const [weekDate, setWeekDate] = useState(getStartOfWeek(new Date()));
  const [isSaving, setIsSaving] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);
  const [showNodata, setShowNodata] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [selectedReportee, setSelectedReportee] = useState('');
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [timesheet, setTimesheet] = useState<ITimesheet>();
  const [history, setHistory] = useState<ITimesheetHistory[]>();
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const [reasonForRejection, setReasonForRejection] = useState('');
  const [autoFillToggle, setAutoFillToggle] = useState(false);
  const [autoFill, setAutoFill] = useState(false);
  const { data: response, loading: timeSheetLoading } =
    useSelector(getTimesheet);

  const {
    data: projects,
    loading: projectsLoading,
    status: projectsStatus,
  } = useSelector(getAssignedProjects);
  const { data: activities, loading: activitiesLoading } =
    useSelector(getActivities);
  const { history: historyData, historyLoading: timeSheetHistoryLoading } =
    useSelector(getTimesheetHistoryData);
  const userRoles = useSelector(getUserRoles);

  const onWorkWeekChange = (e) => {
    setWeekType(e.target.value);
    const workWeekTimesheet = timesheetModelObj.fromJson(
      { ...response },
      e.target.value === 'work_week',
      manager,
    );

    const newData = Object.assign({}, workWeekTimesheet);

    setTimesheet({ ...newData });
  };

  const onWeekChange = (date) => {
    setShowNodata(false);
    setWeekDate(date);
    setTimesheet(null);
    dispatch(
      getTimesheetByUser({
        resource: selectedReportee || auth?.user?.profile?.email,
        date: dayjs(date || new Date()).format('YYYY-MM-DD'),
      }),
    );
  };

  const onReporteeChange = (userEmail: string) => {
    setSelectedReportee(userEmail);
    setShowNodata(false);
    setTimesheet(null);
    dispatch(
      getTimesheetByUser({
        resource: userEmail || auth?.user?.profile?.email,
        date: dayjs(weekDate || new Date()).format('YYYY-MM-DD'),
      }),
    );
  };

  const onToggleAutoFill = async () => {
    setAutoFillToggle(true);
    await dispatch(
      submitAutoFillInTimesheet({
        payload: { email: auth?.user?.profile?.email, autoFill: !autoFill },
      }),
    );
    await dispatch(
      getTimesheetByUser({
        resource: auth?.user?.profile?.email,
        date: dayjs(weekDate || new Date()).format('YYYY-MM-DD'),
      }),
    );
    setAutoFillToggle(false);
  };

  const onSave = async () => {
    setIsSaving(true);
    const serialisedTimesheet = TimesheetModel.toJson(timesheet);

    await dispatch(
      saveTimesheet({
        resource: selectedReportee || auth?.user?.profile?.email,
        date: dayjs(weekDate || new Date()).format('YYYY-MM-DD'),
        payload: serialisedTimesheet.items,
      }),
    );

    await dispatch(
      getTimesheetByUser({
        resource: auth?.user?.profile?.email,
        date: dayjs(weekDate || new Date()).format('YYYY-MM-DD'),
      }),
    );

    setIsSaving(false);
  };

  // Function to open the history modal
  const GetHistory = async () => {
    setIsLoadingHistory(true);
    await dispatch(
      getTimesheetHistory({
        resource: selectedReportee || auth?.user?.profile?.email,
        date: dayjs(weekDate || new Date()).format('YYYY-MM-DD'),
      }),
    );
    setIsLoadingHistory(false);
    setShowHistoryModal(true);
  };
  // Function to close the history modal
  const closeHistoryModal = () => {
    setHistory([]);
    setShowHistoryModal(false);
  };
  const confirmSubmit = () => {
    setShowConfirmModal(true);
  };

  const onSubmit = async () => {
    setIsSubmitting(true);
    const serialisedTimesheet = TimesheetModel.toJson(timesheet);

    await dispatch(
      submitTimesheet({
        date: dayjs(weekDate || new Date()).format('YYYY-MM-DD'),
        resource: selectedReportee || auth?.user?.profile?.email,
        payload: {
          ...serialisedTimesheet,
          status: 1,
        },
      }),
    );

    await dispatch(
      getTimesheetByUser({
        resource: auth?.user?.profile?.email,
        date: dayjs(weekDate || new Date()).format('YYYY-MM-DD'),
      }),
    );

    setIsSubmitting(false);
  };

  const onRejectClick = () => {
    setReasonForRejection('');
    setShowRejectModal(true);
  };

  const handleRejectConfirm = async () => {
    if (!reasonForRejection) {
      toast.error('Enter the reason for rejection');
      setShowRejectModal(true);

      return;
    }
    setIsRejecting(true);

    const serialisedTimesheet = TimesheetModel.toJson(timesheet);

    await dispatch(
      submitTimesheet({
        date: dayjs(weekDate || new Date()).format('YYYY-MM-DD'),
        resource: auth?.user?.profile?.email,
        payload: {
          status: 3,
          employee: selectedReportee,
          reason: reasonForRejection,
          items: serialisedTimesheet.items,
        },
        message: 'Rejected reason submitted successfully',
      }),
    );
    await dispatch(
      getTimesheetByUser({
        resource: selectedReportee,
        date: dayjs(weekDate || new Date()).format('YYYY-MM-DD'),
      }),
    );

    setIsRejecting(false);
  };

  useEffect(() => {
    if (response && response.id != null && !timeSheetLoading) {
      const formattedTimesheet = timesheetModelObj.fromJson(
        response,
        weekType === 'work_week',
        manager,
      );

      setAutoFill(formattedTimesheet.autoFill);
      setTimesheet(formattedTimesheet);
      setShowNodata(!response.projects.length);
    } else {
      setTimesheet(null);
      if (!isFirstRender) {
        setShowNodata(true);
      }
    }
  }, [response]);

  useEffect(() => {
    if (
      auth?.user?.profile?.email &&
      activities &&
      !timeSheetLoading &&
      !projectsLoading &&
      !activitiesLoading
    ) {
      dispatch(
        getTimesheetByUser({
          resource: auth?.user?.profile?.email,
          date: dayjs(weekDate || new Date()).format('YYYY-MM-DD'),
        }),
      );
    }
  }, [
    auth?.user?.profile?.email,
    projects,
    activities,
    projectsLoading,
    activitiesLoading,
  ]);

  useEffect(() => {
    setIsFirstRender(false);
  }, [isFirstRender]);

  useEffect(() => {
    return () => {
      dispatch(actions.resetTimesheet());
    };
  }, []);

  useEffect(() => {
    if (historyData && historyData.length && !timeSheetHistoryLoading) {
      setHistory(historyData);
    }
  }, [historyData]);

  return (
    <Card bg="light" text="dark" className="h-100 bg-white border-0 shadow">
      <TimesheetFilters
        weekDate={weekDate}
        onWeekChange={onWeekChange}
        onWorkWeekChange={onWorkWeekChange}
        onReporteeChange={onReporteeChange}
        weekType={weekType}
        teamTime={teamTime}
        autoFillVal={timesheet ? timesheet.autoFill : autoFill}
        onToggleAutoFill={onToggleAutoFill}
        autoFillToggleStatus={autoFillToggle}
        submissionStatus={TimesheetStatusMap[response.status] === 'Submitted'}
      />
      {TimesheetStatusMap[timesheet?.status] === 'Submitted' ? (
        <Alert variant="warning" className="w-90 mx-3 py-2 my-2">
          Timesheet Submitted on &nbsp;
          {getDateandTime(timesheet.submissionDate).localDate} &nbsp;
          {getDateandTime(timesheet.submissionDate).localTime}
        </Alert>
      ) : TimesheetStatusMap[timesheet?.status] === 'Rejected' ? (
        <Alert variant="danger" className="w-90 mx-3 py-2 my-2">
          Timesheet Rejected on &nbsp;
          {getDateandTime(timesheet.rejectionDate).localDate} &nbsp;
          {getDateandTime(timesheet.rejectionDate).localTime}
        </Alert>
      ) : (
        ''
      )}
      <Card.Body className="h-100 p-0 m-0">
        {!isSubmitting &&
        !isRejecting &&
        !isSaving &&
        !showNodata &&
        autoFillToggle &&
        (projectsLoading || activitiesLoading || timeSheetLoading) ? (
          <TableLoader />
        ) : (
          ''
        )}

        {!showNodata &&
        !autoFillToggle &&
        timesheet &&
        timesheet.projects?.length ? (
          <GridTable
            data={timesheet}
            columns={timesheet.columns}
            startDate={timesheet.startDate}
            endDate={timesheet.endDate}
            disabled={timesheet.disabled || teamTime}
            weekType={weekType}
            isSubmitted={TimesheetStatusMap[response.status] === 'Submitted'}
            isAutoFillEnabled={autoFill}
          />
        ) : (
          ''
        )}
        {!teamTime ? (
          <ConfirmModal
            title="Are you sure you want to submit the Timesheet?"
            description={`Once Timesheet is submitted, it can't be edited.`}
            show={showConfirmModal}
            onCancel={() => setShowConfirmModal(false)}
            onConfirm={() => {
              setShowConfirmModal(false);
              onSubmit();
            }}
          />
        ) : (
          <RejectModal
            show={showRejectModal}
            title={
              <>
                Enter Rejected Reason <span style={{ color: 'red' }}>*</span>
              </>
            }
            inputValue={reasonForRejection}
            onCancel={() => setShowRejectModal(false)}
            onConfirm={() => {
              setShowRejectModal(false);
              handleRejectConfirm();
            }}
            onInputChange={setReasonForRejection}
          />
        )}
      </Card.Body>
      <Card.Footer className="d-flex align-items-center justify-content-between px-3 py-2 bg-white">
        <Legends />
        {!teamTime && (
          <Button
            variant="primary"
            style={{ width: '200px' }}
            className="text-white"
            disabled={response.id === 0 ? true : false}
            onClick={async () => {
              await GetHistory();
            }}
          >
            {isLoadingHistory ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-2 border-1 text-dark"
                />
                Loading...
              </>
            ) : (
              'History'
            )}
          </Button>
        )}
        <HistoryModal
          show={showHistoryModal}
          onHide={closeHistoryModal}
          history={history}
          isLoading={isLoadingHistory}
        />
        {!teamTime && timesheet && timesheet.projects?.length ? (
          <Stack direction="horizontal" gap={3}>
            <Button
              className="d-flex align-items-center justify-content-center"
              variant="outline-primary"
              onClick={onSave}
              disabled={timesheet.disabled || isSaving}
            >
              {isSaving ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-2 border-1 text-dark"
                  />
                  Saving...
                </>
              ) : (
                'Save'
              )}
            </Button>
            <Button
              variant="primary"
              style={{ width: '200px' }}
              className="text-white"
              onClick={confirmSubmit}
              disabled={timesheet.disabled || isSubmitting}
            >
              {isSubmitting ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-2 border-1 text-dark"
                  />
                  Submitting...
                </>
              ) : (
                'Submit'
              )}
            </Button>
          </Stack>
        ) : (
          TimesheetStatusMap[timesheet?.status] === 'Submitted' &&
          selectedReportee &&
          TimesheetStatusMap[timesheet?.status] !== 'Rejected' && (
            <CanView
              userRole={userRoles.data}
              allowedRoles={['CapexAdManager']}
            >
              <Stack direction="horizontal" gap={3}>
                <Button
                  variant="primary"
                  style={{ width: '200px' }}
                  className="text-white"
                  onClick={onRejectClick}
                >
                  {isRejecting ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="me-2 border-1 text-dark"
                      />
                      rejecting...
                    </>
                  ) : (
                    'Reject'
                  )}
                </Button>
              </Stack>
            </CanView>
          )
        )}
      </Card.Footer>
    </Card>
  );
};

export default TimeSheet;
