// Third party imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

// App imports
import { client } from 'src/utils/api';
// import {
//   deserialiseTimesheet,
//   generateTimesheetResponse,
// } from 'src/utils/generateTimesheet';

export const getTimesheetByUser: any = createAsyncThunk(
  'timesheet/getTimesheetByUser',
  async ({ resource, date }: any, { rejectWithValue }) => {
    try {
      const response = await client.get(`Timesheet/${date}/${resource}`);

      // const isDateInPresentWeek = isDateInCurrentWeek(date);
      // const isDateInFuture = isFutureDate(date);

      // if (!response || !response.data || !response.data.projects.length) {
      //   const timesheetResponse = generateTimesheetResponse(projects, date);
      //   const timesheet = deserialiseTimesheet(timesheetResponse);

      //   return timesheet;
      // }

      return response.data;
    } catch (err) {
      toast.error('Error fetching timesheet.');

      return rejectWithValue(err);
    }
  },
);

export const saveTimesheet: any = createAsyncThunk(
  'timesheet/saveTimesheet',
  async ({ date, resource, payload }: any, { rejectWithValue }) => {
    try {
      const response = await client.post(
        `Timesheet/${date}/${resource}`,
        payload,
      );

      toast.success('Timesheet saved Successfully');

      return response.data;
    } catch (err) {
      toast.error('Error saving timesheet.');

      return rejectWithValue(err);
    }
  },
);

export const submitTimesheet: any = createAsyncThunk(
  'timesheet/submitTimesheet',
  async ({ date, resource, payload, message }: any, { rejectWithValue }) => {
    try {
      const response = await client.post(
        `Timesheet/${date}/${resource}/submit`,
        payload,
      );

      toast.success(message ?? 'Timesheet Submitted Successfully');

      return response.data;
    } catch (err) {
      toast.error('Error submitting timesheet.');

      return rejectWithValue(err);
    }
  },
);

export const getTimesheetHistory: any = createAsyncThunk(
  'timesheet/getTimeSheetHistory',
  async ({ resource, date }: any, { rejectWithValue }) => {
    try {
      const response = await client.get(
        `Timesheet/${date}/${resource}/GetTimeSheetHistory`,
      );

      return response.data;
    } catch (err) {
      toast.error('Error fetching timesheet.');

      return rejectWithValue(err);
    }
  },
);

enum Status {
  idle = 'idle',
  loading = 'loading',
  succeeded = 'succeeded',
  failed = 'failed',
}

const timesheetSlice = createSlice({
  name: 'timesheet',
  initialState: {
    data: {},
    history: [],
    error: '',
    status: Status.idle,
    loading: false,
    historyLoading: false,
  },
  reducers: {
    resetTimesheet: (state) => {
      state.data = {
        data: {},
        history: [],
        error: '',
        status: Status.idle,
        historyLoading: false,
      };
    },
  },
  extraReducers: {
    [saveTimesheet.pending]: (state) => {
      state.loading = true;
      state.status = Status.loading;
    },
    [saveTimesheet.fulfilled]: (state) => {
      state.loading = false;
      // state.data = action.payload;
      state.status = Status.succeeded;
    },
    [saveTimesheet.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.status = Status.failed;
    },
    [submitTimesheet.pending]: (state) => {
      state.loading = true;
      state.status = Status.loading;
    },
    [submitTimesheet.fulfilled]: (state, action) => {
      state.loading = false;
      // state.data = action.payload;
      state.status = Status.succeeded;
    },
    [submitTimesheet.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.status = Status.failed;
    },

    [getTimesheetByUser.pending]: (state) => {
      state.loading = true;
      state.status = Status.loading;
    },
    [getTimesheetByUser.fulfilled]: (state, action) => {
      state.loading = false;
      // Deserialse the response here
      state.data = action.payload;
      state.status = Status.succeeded;
    },
    [getTimesheetByUser.rejected]: (state, action) => {
      state.loading = false;
      state.status = Status.failed;
      state.data = {};
      state.error = action.error.message;
    },

    [getTimesheetHistory.pending]: (state) => {
      state.historyLoading = true;
      state.status = Status.loading;
    },
    [getTimesheetHistory.fulfilled]: (state, action) => {
      state.historyLoading = false;
      state.history = action.payload;
      state.status = Status.succeeded;
    },
    [getTimesheetHistory.rejected]: (state, action) => {
      state.historyLoading = false;
      state.status = Status.failed;
      state.error = action.error.message;
    },
  },
});

export const getTimesheet = (state) => state.timesheet;

export const getTimesheetHistoryData = (state) => state.timesheet;

const actions = timesheetSlice.actions;

export { actions };
export default timesheetSlice.reducer;
