// Third party imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
// App imports
import { client } from 'src/utils/api';

export const fetchAllUsers: any = createAsyncThunk(
  'users/fetchAllUsers',
  async ({ query }: any, { rejectWithValue }) => {
    try {
      // const params = {
      //   search: query,
      // };

      // const queryParams = Object.keys(params)
      //   .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
      //   .join('&');

      const response = await client.get(`User/search/${query}`, {});

      let transformedData = [];

      if (response && response.data && Array.isArray(response.data)) {
        transformedData = response.data.map((item: any, index) => {
          return {
            name: item.name,
            id: item.WorkerRef || index,
            email: item.email,
          };
        });
      }

      return transformedData || [];
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
export const fetchAllRoles: any = createAsyncThunk(
  'User/fetchAllRoles',
  async (
    { email, roles }: { email: string; roles: string[] },
    { rejectWithValue },
  ) => {
    try {
      const reqObj = {
        email: email,
        roles: roles,
      };

      const response = await client.post('User/roles', reqObj);

      return response.data;
    } catch (err) {
      toast.error('Error fetching roles.');

      return rejectWithValue(err);
    }
  },
);

export const fetchToken: any = createAsyncThunk(
  'User/fetchToken',
  async ({ email }: { email: string }, { rejectWithValue }) => {
    try {
      const response = await client.get(`User/token?email=${email}`);

      return response.data;
    } catch (err) {
      toast.error('Error fetching token.');

      return rejectWithValue(err);
    }
  },
);
export const fetchAllUserbyManager: any = createAsyncThunk(
  'User/GetUsersByManager',
  async ({ email }: any, { rejectWithValue }) => {
    try {
      const response = await client.get(`User/usersByManager?email=${email}`);

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
export const submitAutoFillInTimesheet: any = createAsyncThunk(
  'User/autoFill',
  async ({ payload }: any, { rejectWithValue }) => {
    try {
      const response = await client.post(`User/autoFill`, payload);

      return response.data;
    } catch (err) {
      toast.error('Error in auto fill toggle.');

      return rejectWithValue(err);
    }
  },
);

enum Status {
  idle = 'idle',
  loading = 'loading',
  succeeded = 'succeeded',
  failed = 'failed',
}

const usersSlice = createSlice({
  name: 'users',
  initialState: {
    data: {},
    roles: [],
    token: '',
    error: '',
    status: Status.idle,
    ManagerResource: [],
    loading: false,
  },
  reducers: {
    resetToken: (state) => {
      state.token = '';
    },
  },
  extraReducers: {
    [fetchAllUsers.pending]: (state) => {
      state.loading = true;
      state.status = Status.loading;
    },
    [fetchAllUsers.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = [action.payload];
      state.status = Status.succeeded;
    },
    [fetchAllUsers.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.status = Status.failed;
    },

    [fetchAllRoles.pending]: (state) => {
      state.loading = true;
      state.status = Status.loading;
    },
    [fetchAllRoles.fulfilled]: (state, action) => {
      state.loading = false;
      state.roles = action.payload;
      state.status = Status.succeeded;
    },
    [fetchAllRoles.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.status = Status.failed;
    },

    [fetchToken.pending]: (state) => {
      state.loading = true;
      state.status = Status.loading;
      state.token = '';
    },
    [fetchToken.fulfilled]: (state, action) => {
      state.loading = false;
      state.token = action.payload;
      state.status = Status.succeeded;
    },
    [fetchToken.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.status = Status.failed;
    },
    [fetchAllUserbyManager.pending]: (state) => {
      state.loading = true;
      state.status = Status.loading;
    },
    [fetchAllUserbyManager.fulfilled]: (state, action) => {
      state.loading = false;
      state.ManagerResource = action.payload;
      state.status = Status.succeeded;
    },
    [fetchAllUserbyManager.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.status = Status.failed;
    },
    [submitAutoFillInTimesheet.pending]: (state) => {
      state.loading = true;
      state.status = Status.loading;
    },
    [submitAutoFillInTimesheet.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.status = Status.succeeded;
    },
    [submitAutoFillInTimesheet.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.status = Status.failed;
    },
  },
});

export const getUsers = (state) => state.users;

export const getUserRoles = (state) => {
  return {
    data: state.users ? state.users.roles : [],
  };
};

export const getToken = (state) => {
  return {
    data: state.users ? state.users.token : '',
  };
};
export const getResourceByManager = (state) => {
  return {
    data: state.users.ManagerResource,
    status: state.users.ManagerResource,
    loading: state.users.ManagerResource,
  };
};
const actions = usersSlice.actions;

export { actions };
export default usersSlice.reducer;
