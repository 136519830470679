// React imports
import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

// App imports
import GlobalActivities from './GlobalActivities';
import ProjectActivities from './ProjectActivities';

const ActivitiesList = ({ isModalOpen }) => {
  const [key, setKey] = useState('global');

  return (
    <Card bg="light" text="dark" className="h-100 bg-white border-0 shadow">
      <Card.Body className="h-full overflow-hidden">
        <Tabs
          id="justify-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tab
            eventKey="global"
            title="Global Activities"
            className="py-2 px-4"
          >
            {key === 'global' ? <GlobalActivities active={key} /> : ''}
          </Tab>
          <Tab
            eventKey="project"
            title="Project Activities"
            className="py-2 px-4"
          >
            {key === 'project' ? (
              <ProjectActivities isModalOpen={isModalOpen} />
            ) : (
              ''
            )}
          </Tab>
        </Tabs>
      </Card.Body>
    </Card>
  );
};

export default ActivitiesList;
