import React, { useEffect } from 'react';
import { Container, Row, Col, Offcanvas, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import Logo from '../assets/logo.png';
import NavBar from 'src/components/Navbar';
import { SideNavItems } from 'src/components/SideNav';
import { getProjects } from 'src/redux/features/projects/projectsSlice';
import {
  actions,
  fetchActivities,
  fetchAssignedProjects,
  fetchProjects,
  getActivities,
  getPreferences,
} from 'src/redux/features/session/slice';
import {
  fetchAllRoles,
  getUserRoles,
} from 'src/redux/features/users/usersSlice';
import { useAuth } from 'react-oidc-context';
import { parseRoles } from 'src/utils/helpers';
import { FullScreenLoader } from 'src/components/FullScreenLoader';

const App = () => {
  const dispatch = useDispatch();
  const { showMenu } = useSelector(getPreferences);
  const auth = useAuth();
  const { status: projectStatus, loading: projectsLoading } =
    useSelector(getProjects);
  const { status: activityStatus, loading: activitiesLoading } =
    useSelector(getActivities);

  const userRoles = useSelector(getUserRoles);

  const handleClose = () => {
    dispatch(actions.setOffCanvasMenu());
  };

  useEffect(() => {
    if (activityStatus === 'idle' && !activitiesLoading) {
      dispatch(fetchActivities());
    }
  }, [activityStatus, dispatch]);

  useEffect(() => {
    if (projectStatus === 'idle' && !projectsLoading) {
      dispatch(fetchAssignedProjects());
    }
  }, [projectStatus, dispatch]);

  const { status: getProjectStatus, loading: getAllProjectsLoading } =
    useSelector(getProjects);

  useEffect(() => {
    if (getProjectStatus === 'idle' && !getAllProjectsLoading) {
      dispatch(fetchProjects());
      console.log('fetchProjects', typeof fetchProjects());
    }
  }, [getProjectStatus, dispatch, getAllProjectsLoading]);

  useEffect(() => {
    if (
      !userRoles.data.length &&
      auth.user?.profile?.email &&
      auth.user?.profile?.role
    ) {
      dispatch(
        fetchAllRoles({
          email: auth.user.profile.email,
          roles: parseRoles(auth.user.profile.role as string),
        }),
      );
    }
  }, [auth.user.profile.email, auth.user.profile.role, userRoles.data.length]);

  if (!userRoles || !userRoles.data.length) {
    return <FullScreenLoader />;
  }

  return (
    <div style={{ height: '100vh' }}>
      <NavBar />
      <Container fluid>
        <ToastContainer transition={Slide} />

        <Row>
          <Col
            xs={2}
            className="position-fixed bg-white text-dark px-2 pt-5 fixed-left d-none d-lg-block mt-5"
            style={{ height: 'calc(100vh - 80px)' }}
          >
            <SideNavItems />
          </Col>

          {/* Scrollable Content */}
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={10}
            className="p-3 offset-sidebar pt-5 mt-5"
            style={{
              marginLeft: 'auto',
              backgroundColor: '#f5f7f8',
              height: 'calc(100vh - 3rem)',
            }}
          >
            {/* <div className="scrollable-content" style={{}}> */}
            <Outlet />
            {/* </div> */}
          </Col>
        </Row>
        <Offcanvas
          show={showMenu}
          onHide={handleClose}
          style={{ width: '250px' }}
        >
          <Offcanvas.Header closeButton className="bg-primary">
            <Image src={Logo} className="py-1" />
          </Offcanvas.Header>
          <Offcanvas.Body>
            <SideNavItems />
          </Offcanvas.Body>
        </Offcanvas>
      </Container>
    </div>
  );
};

export default App;
