import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
export const isAuthorised = (roles: string[] = [], allowedRoles: string[]) => {
  if (!Array.isArray(roles)) {
    return false;
  }

  return roles.some((role) => allowedRoles.includes(role));
};

export const parseRoles = (roles: string) => {
  return JSON.parse(roles || '[]');
};

export const generateDates = (startDate, endDate, workWeek = true) => {
  const dates = [];
  let currentDate = dayjs(startDate);

  while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, 'day')) {
    dates.push(currentDate.format('YYYY-MM-DD'));
    currentDate = currentDate.add(1, 'day');
  }

  const filteredDates = dates.filter((date) => {
    return isSaturdayOrSunday(date) === false;
  });

  return workWeek ? filteredDates : dates;
};

export function isSaturdayOrSunday(dateString) {
  // Parse the input date string using dayjs
  const date = dayjs(dateString).startOf('day');

  // Extract the day of the week (0 is Sunday, 6 is Saturday)
  const dayOfWeek = date.day();

  // Check if the day is Saturday (6) or Sunday (0)
  return dayOfWeek === 0 || dayOfWeek === 6;
}

export function isFutureDateAndNotInCurrentWeek(date) {
  const givenDate = dayjs(date);
  // Get the start and end of the current week (Sunday - Saturday)
  const currentWeekStart = dayjs().startOf('week');
  const currentWeekEnd = dayjs().endOf('week');

  // Check if the given date is after the current week's end
  const isFuture = givenDate.isAfter(currentWeekEnd);

  // Check if the given date is not within the current week range (excluding today)
  const isNotInCurrentWeek =
    (!givenDate.isSame(currentWeekEnd) ||
      !givenDate.isBefore(currentWeekEnd)) &&
    (!givenDate.isSame(currentWeekStart) ||
      !givenDate.isAfter(currentWeekStart));

  // Combine both conditions: future and not in current week
  return isFuture && isNotInCurrentWeek;
}

export const generateWeekDates = (startDate, endDate, workWeek) => {
  const weekDates = generateDates(startDate, endDate, workWeek);

  const filteredWeekDates = weekDates.map((date) => {
    return dayjs(date).toDate();
  });

  return filteredWeekDates;
};

export const formatDate = (date) => {
  const givenDate = dayjs(date);

  return givenDate.format('YYYY-MM-DD');
};

export const getPreviousWeekDate = (date) => {
  const currentDate = dayjs(date);
  const previousWeek = currentDate.subtract(1, 'week').startOf('week');
  const previousWeekDate = previousWeek.format('YYYY-MM-DD');

  return previousWeekDate;
};

export const getDateandTime = (utcDate) => {
  const date = dayjs.utc(utcDate).local();
  const localDate = date.format('MM/DD/YYYY');
  const localTime = date.format('HH:mm A');

  return { localDate, localTime };
};
