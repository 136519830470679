import React, { useEffect, useState } from 'react';
import { AccessDeniedModal } from '../AccessDeniedModal';
import { useNavigate } from 'react-router-dom';
import { isAuthorised, parseRoles } from 'src/utils/helpers';

const HasAccess = ({ userRole, allowedRoles, children }) => {
  const [roles] = useState<string[]>([]);
  const [show, setShow] = useState(false);
  const [hasAccess, setHasAccess] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    setShow(false);
    navigate('/timesheet');
  };

  useEffect(() => {
    const authorised = isAuthorised(userRole, allowedRoles);

    setHasAccess(authorised);
    setShow(!authorised);
  }, [userRole, allowedRoles]);

  if (hasAccess) {
    return <>{children}</>;
  }

  return <AccessDeniedModal show={show} onHide={handleClose} />;
};

export default HasAccess;
