// React imports
import React, { useEffect, useState } from 'react';

// Third-party imports
import { Typeahead } from 'react-bootstrap-typeahead';
import { useSelector } from 'react-redux';

// App imports
import { getAssignedProjects } from 'src/redux/features/session/slice';

export const AssignedProjectsAutoComplete = ({
  onSelectedProjectChange,
  style,
  selectedProject,
}: {
  onSelectedProjectChange: any;
  style: any;
  selectedProject?: any;
}) => {
  const { data: projects, loading: projectsLoading } =
    useSelector(getAssignedProjects);

  return (
    <Typeahead
      caseSensitive={true}
      id="filtering-example2"
      options={
        selectedProject
          ? projects.filter((val) => val.name != selectedProject)
          : projects
      }
      labelKey="name"
      placeholder="Select Project"
      onChange={onSelectedProjectChange}
      style={style}
      renderMenuItemChildren={(option: any) => (
        <>
          <span>{option.name}</span>
        </>
      )}
    />
  );
};
