// Third party imports
import { Button } from 'react-bootstrap';

// App imports
import EditInput from '../../../../components/Input';
import { useEffect, useState } from 'react';

export const GridTableBodyRow = ({
  row,
  rowHeaders,
  index,
  update,
  toggleActivity,
  onAddNewRow,
  onBodyRowTotalHeaderDurationChange,
  disabled,
}) => {
  const [value, setValue] = useState(
    rowHeaders.reduce((acc, col) => acc + col.duration, 0),
  );

  const onChange = (e, rowIndex) => {
    const parseValue = Number(e.target.value);

    setValue(parseValue);
    onBodyRowTotalHeaderDurationChange(parseValue, rowIndex);
  };

  useEffect(() => {
    setValue(rowHeaders.reduce((acc, col) => acc + col.duration, 0));
  });

  return (
    <div className="row border-bottom m-0" key={row.projectId + index}>
      <div className="col-4 d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center w-75">
          <Button
            variant="link"
            onClick={() => onAddNewRow(index, row.projectId)}
            disabled={
              disabled ||
              (row.activityRows &&
                row.activityRows.length > row.activities.length) ||
              !row.isExpanded
            }
          >
            <i
              title="Add Activity"
              className="bi bi-plus-circle-fill text-primary"
            ></i>
          </Button>
          {/* )} */}
          <Button
            variant="link"
            className="text-primary d-flex align-items-center justify-content-between text-decoration-none"
            style={{ flexBasis: '60%' }}
            data-bs-toggle="collapse"
            data-bs-target={`#${row.projectId}-${row.projectName.replaceAll(' ', '_')}`}
            onClick={() => {
              toggleActivity(row);
            }}
          >
            <span>{row.projectName || '-'}</span>
            {row?.activityRows?.length ? (
              <i
                title="Toggle Activity"
                className={`bi bi-chevron-${row.isExpanded ? 'up' : 'down'}`}
              />
            ) : (
              ''
            )}
          </Button>
        </div>
        <EditInput
          rowIndex={index}
          colIndex={-1}
          update={onChange}
          value={Number(value.toFixed(2))}
          className="w-25"
          isTotalInput
          max={rowHeaders.length * 24}
          disabled={disabled}
          style={{
            backgroundColor: '#0db7f01a',
          }}
        />
      </div>
      {rowHeaders.map((col, colIndex) => (
        <div
          key={`${row.projectId}-header-${colIndex}`}
          className="col d-flex justify-content-center px-2 py-3"
        >
          <EditInput
            rowIndex={index}
            colIndex={colIndex}
            update={update}
            value={col?.duration}
            disabled={disabled}
            style={{
              backgroundColor: '#0db7f01a',
            }}
          />
        </div>
      ))}
      {/* <Button
        style={{ width: '100px' }}
        variant="secondary"
        onClick={() => onAddNewRow(index, row.projectId)}
      >
        Add
      </Button> */}
    </div>
  );
};
