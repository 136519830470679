import dayjs from 'dayjs';
import React, { forwardRef, useRef, useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { generateWeekDates } from 'src/utils/helpers';
import 'react-datepicker/dist/react-datepicker.css';

const CustomWeekInput = forwardRef(({ value, onClick }: any, ref: any) => {
  return (
    <Form.Control
      onClick={onClick}
      className="p-0 rounded-0 rounded-end border-start-0"
      ref={ref}
      placeholder="Select Week"
      aria-label="Select Week"
      aria-describedby="Week picker for choosing the desired week"
      value={value}
      style={{ border: '1px solid white', width: '90px' }}
    />
  );
});

CustomWeekInput.displayName = 'CustomWeekInput';

const CustomEndWeekInput = forwardRef(({ value, onClick }: any, ref: any) => {
  return (
    <Form.Control
      onClick={onClick}
      ref={ref}
      placeholder="Select End Week"
      aria-label="Select End Week"
      aria-describedby="End week picker for choosing the desired end week"
      value={value}
      className="p-0 ps-2"
      style={{
        border: '1px solid white',
        width: '100px',
      }}
    />
  );
});

CustomEndWeekInput.displayName = 'CustomEndWeekInput';

export const WeekPicker = ({ startDate, onWeekChange, weekType }) => {
  const [date, setDate] = useState(startDate);
  const [endDate, setEndDate] = useState(null);
  const ref = useRef(null);
  const endRef = useRef(null);

  useEffect(() => {
    setDate(startDate);
    setEndDate(calculateEndDate(startDate));
  }, [startDate]);

  useEffect(() => {
    const newStartDate = calculateStartDate(date);

    setDate(newStartDate);
    setEndDate(calculateEndDate(newStartDate));
  }, [weekType]);

  const calculateStartDate = (date) => {
    if (weekType === 'week') {
      return dayjs(date).startOf('week').toDate();
    } else {
      return dayjs(date).startOf('week').add(1, 'day').toDate();
    }
  };

  const calculateEndDate = (date) => {
    if (weekType === 'work_week') {
      return dayjs(calculateStartDate(date))
        .endOf('week')
        .subtract(1, 'day')
        .toDate();
    } else {
      return dayjs(calculateStartDate(date)).endOf('week').toDate();
    }
  };

  const calculateStartDateFromEndDate = (endDate) => {
    if (weekType === 'work_week') {
      return dayjs(endDate).startOf('week').add(1, 'day').toDate();
    } else {
      return dayjs(endDate).startOf('week').toDate();
    }
  };

  return (
    <div className="position-relative d-flex align-items-center">
      <DatePicker
        ref={ref}
        selected={calculateStartDate(date)}
        onChange={(selectedDate) => {
          const newStartDate =
            weekType === 'week'
              ? dayjs(selectedDate).startOf('week').toDate()
              : dayjs(selectedDate).startOf('week').add(1, 'day').toDate();

          setDate(newStartDate);
          setEndDate(calculateEndDate(newStartDate));
          const weekId = ref.current.input.value.split('/')[0];

          onWeekChange(newStartDate, weekId);
        }}
        locale="en"
        placeholderText="Select Week"
        highlightDates={
          date
            ? generateWeekDates(
                dayjs(date).startOf('week'),
                dayjs(date).endOf('week'),
                weekType === 'work_week',
              )
            : []
        }
        customInput={<CustomWeekInput />}
      />
      <span style={{ marginLeft: '10px', marginRight: '10px' }}>-</span>
      <DatePicker
        ref={endRef}
        selected={endDate}
        onChange={(selectedEndDate) => {
          const newEndDate =
            weekType === 'work_week'
              ? dayjs(selectedEndDate).endOf('week').subtract(1, 'day').toDate()
              : dayjs(selectedEndDate).endOf('week').toDate();

          const newStartDate = calculateStartDateFromEndDate(newEndDate);

          setDate(newStartDate);
          setEndDate(newEndDate);

          const weekId = ref.current.input.value.split('/')[0];

          onWeekChange(newStartDate, weekId);
        }}
        highlightDates={
          date
            ? generateWeekDates(
                dayjs(date).startOf('week'),
                dayjs(date).endOf('week'),
                weekType === 'work_week',
              )
            : []
        }
        customInput={
          <CustomEndWeekInput
            value={endDate ? dayjs(endDate).format('MM/DD/YYYY') : '-'}
          />
        }
      />
      <Button
        variant="link"
        className="text-dark d-inline align-items-center justify-content-between text-decoration-none py-0 ms-2"
        data-bs-toggle="collapse"
        onClick={() => {
          const newDate = dayjs(date).subtract(1, 'week').toDate();

          setDate(newDate);
          setEndDate(calculateEndDate(newDate));
          onWeekChange(newDate, 0);
        }}
      >
        <i
          title="Previous Week"
          className="bi bi-chevron-left"
          style={{ marginLeft: '-23px' }}
        ></i>
      </Button>
      <Button
        variant="link"
        className="text-dark d-inline align-items-center justify-content-between text-decoration-none py-0 ms-2"
        data-bs-toggle="collapse"
        onClick={() => {
          const newDate = dayjs(date).add(1, 'week').toDate();

          setDate(newDate);
          setEndDate(calculateEndDate(newDate));
          onWeekChange(newDate, 0);
        }}
      >
        <i
          title="Next Week"
          className="bi bi-chevron-right"
          style={{ marginLeft: '-20px' }}
        ></i>
      </Button>
    </div>
  );
};
