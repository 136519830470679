import { Button, Form } from 'react-bootstrap';
import EditInput from '../../../../components/Input';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getActivities } from 'src/redux/features/session/slice';

export const GridTableBodyActivityRow = ({
  row,
  index,
  update,
  activity,
  activityIndex,
  onRemoveNewRow,
  onActivityRowTotalHeaderDurationChange,
  onActivityValueChange,
  disabled,
  max = 24,
}) => {
  const { data: activities } = useSelector(getActivities);
  const [projectActivities, setProjectActivities] = useState(row?.activities);

  const [value, setValue] = useState(
    activity.items.reduce((acc, col) => acc + col.duration, 0),
  );

  const [selectedActivity, setSelectedActivity] = useState(
    activity.activityName,
  );

  const onActivityChange = (value, index, activityIndex) => {
    setSelectedActivity(value);
    onActivityValueChange(value, index, activityIndex, row);
  };

  const onChange = (e, rowIndex, colIndex) => {
    const parseValue = Number(e.target.value);

    setValue(parseValue);
    onActivityRowTotalHeaderDurationChange(parseValue, rowIndex);
  };

  const canShowAddedActivityRow = () => {
    if (row.isExpanded && activity?.isNew) {
      return true;
    }

    if (!row.isExpanded && activity?.isNew) {
      return false;
    }

    if (row.isExpanded) {
      return true;
    }
  };

  useEffect(() => {
    setValue(activity.items.reduce((acc, col) => acc + col.duration, 0));
  });

  if (!projectActivities || !projectActivities.length) {
    return null;
  }

  return (
    <div
      className={`row m-0 border-bottom collapse ${canShowAddedActivityRow() ? 'show' : ''}`}
      id={`${row.projectId}-${row.projectName.replaceAll(' ', '_')}`}
    >
      <div className="col-4 d-flex align-items-center justify-content-between">
        <div className="ps-3 d-flex flex-row w-75 align-items-center">
          <span className="fw-bold">Activity</span>
          <Form.Select
            aria-label="Select Activity"
            autoFocus
            disabled={disabled}
            onChange={(e) =>
              onActivityChange(e.target.value, index, activityIndex)
            }
            className="text-secondary d-flex align-items-center justify-content-between text-decoration-none ms-4"
            style={{ flexBasis: '60%' }}
          >
            <option disabled>Select Activity</option>
            {projectActivities?.map((item, index) => (
              <option
                key={`${item.name}-${item.id}`}
                selected={
                  item?.name?.toLowerCase() ===
                  activity?.activityName?.toLowerCase()
                }
                value={`${item.name}-${item.id}`}
                disabled={row.activityRows.some((activityItem) => {
                  return activityItem.activityName === item.name;
                })}
              >
                {item.name}
              </option>
            ))}
          </Form.Select>
          {row?.activityRows.length > 1 ? (
            <Button
              variant="link"
              disabled={disabled}
              onClick={() => onRemoveNewRow(index, activityIndex)}
            >
              <i
                title="Remove Activity"
                className="bi bi-dash-circle-fill text-danger"
              ></i>
            </Button>
          ) : (
            ''
          )}
        </div>
        <EditInput
          rowIndex={index}
          colIndex={-1}
          update={onChange}
          value={Number(value.toFixed(2))}
          className="w-25"
          isTotalInput={true}
          max={max}
          disabled={disabled}
        />
      </div>
      {activity?.items.map((activityCol, activityColIndex) => (
        <div
          key={`${row.projectId}-${activityCol.date}-${activityColIndex}`}
          className="col d-flex justify-content-center px-2 py-3"
        >
          <EditInput
            rowIndex={activityIndex}
            colIndex={activityColIndex}
            update={update}
            value={activityCol.duration}
            disabled={disabled}
          />
        </div>
      ))}
    </div>
  );
};
