import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import './custom.scss';
import './index.scss';
// Bootstrap Bundle JS
import 'bootstrap/dist/js/bootstrap.bundle.min';

import App from './App';
import { store } from './redux/store';
import { AuthProvider } from 'react-oidc-context';
import { BrowserRouter } from 'react-router-dom';
import { WebStorageStateStore } from 'oidc-client-ts';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const oidcConfig = {
  authority: process.env.REACT_APP_SSO_AUTHORITY, //(string): The URL of the OIDC provider. process.env.REACT_APP_SSO_AUTHORITY
  client_id: process.env.REACT_APP_SSO_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_SSO_REDIRECT_URI, // The URI of your client application to receive a response from the OIDC provider.
  client_secret: process.env.REACT_APP_SSO_CLIENT_SECRET,
  login: process.env.REACT_APP_SSO_LOGIN,
  automaticSilentRenew: true, //(boolean, default: false): Flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration.
  loadUserInfo: true, //(boolean, default: true): Flag to control if additional identity data is loaded from the user info endpoint in order to populate the user's profile.
  // silent_redirect_uri: process.env.SILENT_REDIRECT_URL, //(string): The URL for the page containing the code handling the silent renew.
  // post_logout_redirect_uri: process.LOGOFF_REDIRECT_URL, // (string): The OIDC post-logout redirect URI.
  //audience: "https://example.com", //is there a way to specific the audience when making the jwt
  responseType: 'id_token token', //(string, default: 'id_token'): The type of response desired from the OIDC provider.
  grantType: 'authorization_code',
  scope: process.env.REACT_APP_SSO_SCOPE, //(string, default: 'openid'): The scope being requested from the OIDC provider.
  webAuthResponseType: 'id_token token',
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  onSigninCallback: () => {
    window.history.replaceState({}, document.title, `/timesheet`);
    window.location.pathname = 'timesheet';
  },
};

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider {...oidcConfig}>
        <Provider store={store}>
          <App />
        </Provider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
