import React from 'react';
import { Modal, Table } from 'react-bootstrap';
import { ScrollableContent } from 'src/components/ScrollableContent';
import { ITimesheetHistory } from 'src/models/Timesheet';
import { getDateandTime } from 'src/utils/helpers';
import { TableLoader } from 'src/views/TimeSheet/components/TableLoader';

interface HistoryModalProps {
  show: boolean;
  onHide: () => void;
  history: ITimesheetHistory[] | null;
  isLoading: boolean;
}

export const HistoryModal: React.FC<HistoryModalProps> = ({
  show,
  onHide,
  history,
  isLoading,
}) => {
  return (
    <Modal centered show={show} onHide={onHide} size="xl" scrollable>
      <Modal.Header closeButton>
        <Modal.Title>History Logs</Modal.Title>
      </Modal.Header>
      <ScrollableContent minHeight="100%" maxHeight="100%">
        {history ? (
          <Table striped>
            <thead
              className="position-sticky top-0 bg-white"
              style={{ zIndex: 1000 }}
            >
              <tr>
                <th className="p-3">Event</th>
                <th className="p-3">Date</th>
                <th className="p-3">Time</th>
                <th className="p-3">By</th>
                <th className="p-3">Rejection Reason (if applicable)</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? <TableLoader /> : null}
              {history && history.length > 0 ? (
                history.map((item) => (
                  <tr key={item.timeSheetId} className="text-start border-1">
                    <td className="fw-bold p-3">{item.event}</td>
                    <td className="p-3">
                      {getDateandTime(item?.date).localDate || '-'}
                    </td>
                    <td className="p-3">
                      {getDateandTime(item?.date).localTime || '-'}
                    </td>
                    <td className="p-3">{item.eventCreatedBy}</td>
                    <td className="p-3">{item.reason}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={5}>No history available</td>
                </tr>
              )}
            </tbody>
          </Table>
        ) : (
          <TableLoader />
        )}
      </ScrollableContent>
    </Modal>
  );
};
