import React, { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { CanView } from 'src/components/CanView';
import { ProjectsResourceAutoComplete } from 'src/components/ProjectsResourceAutoComplete';
import { ResourceAutoComplete } from 'src/components/ResourceAutoComplete';
import { SelectReportee } from './SelectReportee';
import { IReportee } from 'src/models/Reportee';
import { useSelector } from 'react-redux';
import { getUserRoles } from 'src/redux/features/users/usersSlice';
const ResourceFilterHoc = ({ teamTime, onUserChange }) => {
  const auth = useAuth();
  const userRoles = useSelector(getUserRoles);
  const [selectedReportee, setSelectedReportee] = useState<string>();
  const [selectedProjectReportee, setSelectedProjectReportee] =
    useState<string>();
  const reportees = (auth?.user?.profile?.reportee as string) || '[]';

  const [parsedReportees, setParsedReportees] = useState();

  const onSelectedReporteeChange = (selected) => {
    if (selected && selected[0]) {
      onUserChange(selected[0].email);

      setSelectedReportee(selected[0].email);
    } else {
      setSelectedReportee(null);
      onUserChange(null);
    }
  };

  const onSelectedResourceChange = (selected) => {
    if (selected && selected[0]) {
      setSelectedReportee(selected[0].email);
      onUserChange(selected[0].email);
    }

    if (selected.length === 0) {
      setSelectedReportee(null);
      onUserChange(null);
    }
  };

  const onSelectedProjectResourceChange = (selected) => {
    if (selected && selected[0]) {
      setSelectedProjectReportee(selected[0].email);
      onUserChange(selected[0].email);
    }

    if (selected.length === 0) {
      setSelectedProjectReportee(null);
      onUserChange(null);
    }
  };

  useEffect(() => {
    setParsedReportees(JSON.parse(reportees));
  }, [reportees]);

  if (!teamTime) {
    return null;
  }

  return (
    <>
      <CanView userRole={userRoles.data} allowedRoles={['CapexAdmin']}>
        <ResourceAutoComplete
          selectedItems={selectedReportee ? [selectedReportee] : []}
          onSelectedResourceChange={onSelectedResourceChange}
          style={{ width: '300px' }}
        />
      </CanView>
      {/* <CanView userRole={userRoles.data} allowedRoles={['CapexProjectLeader']}>
        <ProjectsResourceAutoComplete
          onSelectedProjectChange={onSelectedProjectResourceChange}
        />
      </CanView> */}
      <CanView userRole={userRoles.data} allowedRoles={['CapexAdManager']}>
        <SelectReportee
          data={parsedReportees}
          onItemChange={onSelectedReporteeChange}
        />
      </CanView>
    </>
  );
};

export default ResourceFilterHoc;
