import dayjs from 'dayjs';
import { IProjectResponse as IAssignedProjectResponse } from 'src/models/Project';

import {
  IProjectResponse,
  ITimesheet,
  ITimesheetResponse,
  TimesheetModel,
} from 'src/models/Timesheet';

// const data: Array<IAssignedProjectResponse> = [
//   {
//     id: 1,
//     name: 'Sales Portal',
//     owner: 'deviprasad.das@daikinapplied.com',
//     resources: [
//       'chaitanya.kintali@daikinapplied.com',
//       'string',
//       'harish.co@daikinapplied.com',
//     ],
//   },
//   {
//     id: 2,
//     name: 'Tahoe',
//     owner: 'gaurav.upamanyu@daikinapplied.com',
//     resources: [
//       'deviprasad.das@daikinapplied.com',
//       'chaitanya.kintali@daikinapplied.com',
//       'harish.co@daikinapplied.com',
//     ],
//   },
// ];

export const generateTimesheetResponse = (
  projects: Array<IAssignedProjectResponse>,
  date,
): ITimesheetResponse => {
  const daysInWeek = getWeekDays(new Date(date));
  const timesheetResponse: ITimesheetResponse = {
    startDate: daysInWeek[0],
    endDate: daysInWeek[6],
    projects: [],
    id: -1,
    resource: '',
    weekId: -1,
    status: 0,
    autoFill: false,
  };

  projects.forEach((project) => {
    const projectTimesheet: IProjectResponse = {
      projectId: project.id,
      projectName: project.name,
      items: [],
    };

    // Loop through daysInWeek date and create an item for each day
    daysInWeek.forEach((day) => {
      const item = {
        date: day,
        duration: 0,
        activityTypeId: 1,
        activityName: 'Requirements',
        projectId: project.id,
      };

      projectTimesheet.items.push(item);
    });

    timesheetResponse.projects.push(projectTimesheet);
  });

  return timesheetResponse;
};

export const deserialiseTimesheet = (
  timesheetResponse: ITimesheetResponse,
  workWeek = false,
): ITimesheet => {
  const timesheetModelObj = new TimesheetModel();

  const deserialisedTimesheet = timesheetModelObj.fromJson(
    timesheetResponse,
    false,
    workWeek,
  );

  return deserialisedTimesheet;
};

// Utility function to get all the days in a week given the date

function getWeekDays(date) {
  const dayIndex = date.getDay(); // Get the day index (0 for Sunday, 1 for Monday, ..., 6 for Saturday)
  const firstDayOfWeek = new Date(date); // Create a new date object with the same date

  firstDayOfWeek.setDate(date.getDate() - dayIndex); // Set it to the first day of the week

  const weekDays = []; // Array to store the week days

  for (let i = 0; i < 7; i++) {
    const currentDate = new Date(firstDayOfWeek);

    currentDate.setDate(firstDayOfWeek.getDate() + i); // Increment the date to get each day of the week
    weekDays.push(currentDate.toISOString()); // Push the day name into the array
  }

  return weekDays;
}

export const isFutureDate = (date: Date): boolean => {
  const currentDate = new Date(); // Get the current date
  const currentDay = currentDate.getDate(); // Get the current day

  const givenDay = date.getDate(); // Get the day of the given date

  return givenDay > currentDay; // Compare the given day with the current day
};

// Utility function to check if a date is a past date
export const isPastDate = (date) => {
  const currentDate = new Date(); // Get the current date
  const currentDay = currentDate.getDate(); // Get the current day

  const givenDay = date.getDate(); // Get the day of the given date

  return givenDay < currentDay; // Compare the given day with the current day
};

export function splitAndDistributeHours(totalHours, totalProjects) {
  // Convert totalHours to integer by rounding it to the nearest half point
  totalHours = Math.round(totalHours * 2) / 2;

  // Calculate the even distribution of hours
  const evenHours = Math.floor(totalHours / totalProjects);
  // Calculate the remaining hours after evenly distributing among projects
  let remainingHours = totalHours % totalProjects;

  // Initialize an array to hold the hours allocated to each project
  const hoursPerProject = Array(totalProjects).fill(evenHours);

  // Distribute remaining hours across projects in a round-robin fashion
  let currentProject = 0;

  while (remainingHours > 0) {
    hoursPerProject[currentProject] += 0.5;
    remainingHours -= 0.5;
    currentProject = (currentProject + 1) % totalProjects; // Move to the next project in a circular manner
  }

  return hoursPerProject;
}

export function isInCurrentWeek(date) {
  const currentDate = dayjs();
  const startOfWeek = currentDate.startOf('week');
  const endOfWeek = currentDate.endOf('week');

  return date.isAfter(startOfWeek) && date.isBefore(endOfWeek);
}

export function isInPastWeeks(date) {
  const currentDate = dayjs();
  const startOfCurrentWeek = currentDate.startOf('week');
  const endOfCurrentWeek = currentDate.endOf('week');

  return date.isBefore(startOfCurrentWeek) || date.isAfter(endOfCurrentWeek);
}

export function isInFutureWeek(date) {
  const currentDate = dayjs();
  const startOfNextWeek = currentDate.endOf('week');

  return date.isAfter(startOfNextWeek) || date.isSame(startOfNextWeek);
}

export function isDateInPresentOrPastWeeks(givenDate) {
  return isInCurrentWeek(givenDate) || isInPastWeeks(givenDate);
}
